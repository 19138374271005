function addEventListenerForMobileMenu() {
	var mobileMenu = document.getElementById("mobile-menu");

	$("#mobile-toggle").unbind();

	$("#mobile-toggle").on("click", function (e) {
		e.preventDefault();

		mobileMenu.classList.toggle("closed");
		var body = document.querySelector("body");
		body.setAttribute("modal", "mobile-menu");
		modifyBackButton();
	});

	$("#mobile-toggle-off").on("click", function (e) {
		e.preventDefault();
		mobileMenu.classList.add("closed");
	});
 	$("#mobile-main-menu .btn-open-sub").unbind();

	$("#mobile-main-menu .btn-open-sub").on("click", function (e) {
		e.preventDefault();
		var menu_item = this.closest(".menu-item");
		var sub_menu = menu_item.getElementsByClassName("sub-menu")[0];
		$(sub_menu).slideToggle(500);
		this.classList.toggle("opened");
	});

	$("#mobile-menu a").unbind();

	$("#mobile-menu a").on("click", function () {
		mobileMenu.classList.add("closed");
	});

}


function adjustSidebarActiveMenuItemLiPosition() {
	var li = document.querySelector(".main-sidebar .menu li.active");
	var menu = document.querySelector(".main-sidebar .menu");
	var padding = menu.offsetWidth * 0.04;

	if (li.offsetLeft < menu.scrollLeft) {
		let x = li.offsetLeft - padding;
		if (x < 0) {
			x = 0;
		}
		menu.scrollLeft = x;
	}

	if (li.offsetLeft + li.offsetWidth > menu.offsetWidth) {
		menu.scrollLeft =
			li.offsetLeft + li.offsetWidth - menu.offsetWidth + padding;
	}

	var sub_li = document.querySelector(
		".main-sidebar .menu li.active .sub-menu li.active"
	);
	var sub_menu = document.querySelector(
		".main-sidebar .menu li.active .sub-menu"
	);

	if (sub_li.offsetLeft < sub_menu.scrollLeft) {
		let x = sub_li.offsetLeft;
		if (x < 0) {
			x = 0;
		}
		sub_menu.scrollLeft = x;
	}

	if (sub_li.offsetLeft + sub_li.offsetWidth > sub_menu.offsetWidth) {
		sub_menu.scrollLeft =
			sub_li.offsetLeft + sub_li.offsetWidth - sub_menu.offsetWidth;
	}
}

function backToTop() {
	var body = $("html, body");
	body.stop().animate({ scrollTop: 0 }, 100, "swing");
}

function updateMainSidebarWitdth(pathname) {
	const _width = $(window).width();

	const paths = ["/item-discription"];

	let width = "200px";

	if (_width >= 992) {
		for (const path of paths) {
			if (pathname.startsWith(path)) {
				width = "184px";
				break;
			}
		}
		$(".main-sidebar").css("width", width);
	}
}

function activeShoppingMallMobileMainMenu() {
	const _subActiveLis = $(
		".main-sidebar .nav .menu .menu-item.active .sub-menu li.active"
	);

	if (_subActiveLis.length === 1) {
		const _li = _subActiveLis[0];
		const cateCd = $(_li).attr("cate-cd");

		if (cateCd) {
			$(
				"#main-nav .main-menu .menu-item .sub-menu .sub-menu-item"
			).removeClass("active");
			$("#main-nav .main-menu .menu-item").removeClass("active");

			const _mainSubMenuItems = $(
				"#main-nav .main-menu .menu-item .sub-menu .sub-menu-item[data-program-id=" +
					cateCd +
					"]"
			);

			for (const item of _mainSubMenuItems) {
				$(item).addClass("active");
				$(item).parent().parent().addClass("active");
			}

			const _mobileSubMenuItems = $(
				"#mobile-main-menu .nav .menu-item .sub-menu .sub-menu-item[data-program-id=" +
					cateCd +
					"]"
			);

			for (const item of _mobileSubMenuItems) {
				$(item).addClass("active");
				$(item).parent().parent().addClass("active");
			}
		}
	}
}

function addReWriteSpan() {
	jQuery(document).ready(function ($) {
		var p_spans = document.getElementsByClassName("p-span");
		if (p_spans.length > 0) {
			for (var i = 0; i < p_spans.length; i++) {
				reWriteSpans(p_spans[i]);
			}
		}
	});

	function reWriteSpans(p_span) {
		var str = p_span.innerHTML;
		var new_str = replaceTag(str);
		var str_arr = new_str.split(" ");

		if (str_arr.length > 0) {
			var html = "";
			for (var i = 0; i < str_arr.length; i++) {
				if (isTag(str_arr[i]) == false) {
					html += "<span>";
					html += str_arr[i];
					html += "</span>";
				} else {
					html += str_arr[i];
				}
			}
			p_span.innerHTML = "";
			$(p_span).append(html);
		}
	}

	function replaceTag(str) {
		var str_arr = str.split("");
		var start_index = -1;
		var end_index = -1;

		var tag_arr = [];

		var new_str = str;

		for (var i = 0; i < str_arr.length; i++) {
			if (str_arr[i] == "<") {
				start_index = i;
			}

			if (str_arr[i] == ">") {
				end_index = i - 1;
			}

			if (start_index != -1) {
				if (end_index != -1) {
					var x = "";
					for (var j = start_index; j <= end_index; j++) {
						x += str_arr[j];
					}
					tag_arr.push(x);
					start_index = -1;
					end_index = -1;
				}
			}
		}

		if (tag_arr.length > 0) {
			for (var i = 0; i < tag_arr.length; i++) {
				var code_attribute = " " + getCodeAttribute(tag_arr[i]);
				new_str = new_str.replace(code_attribute, "");
			}
		}
		return new_str;
	}

	function getCodeAttribute(str) {
		var result = "";
		var str_arr = str.split(" ");
		if (str_arr.length == 2) {
			result = str_arr[1];
		}
		return result;
	}

	function isTag(str) {
		var str_arr = str.split("");
		if (str_arr[0] == "<") {
			return true;
		} else {
			return false;
		}
	}
}

function modifyBackToCloseModal() {
	var body = document.querySelector("body");
	body.setAttribute("modal", "office-popup");
	modifyBackButton();
}

function expandContent() {
	$(".intro-content .extend-btn").on("click", function (e) {
		var main_body = this.closest(".main-body");
		var extended_content = main_body.querySelector(".extended-content");
		this.classList.toggle("extended");
		$(extended_content).slideToggle(500);
	});
}

function swiper() {
	var quickswiper = new Swiper(".recentSlide", {
		pagination: {
			el: ".swiper-pagination",
			type: "fraction",
		},
		loop: true,
		autoplay: {
			delay: 2500,
			disableOnInteraction: false,
		},
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	});
}

function brandsSlider() {
	new Swiper(".brandSlider", {
		slidesPerView: 3,
		slidesPerGroup: 3,
		spaceBetween: 2,
		centeredSlides: true,
		centeredSlidesBounds: true,
		breakpoints: {
			320: {
				slidesPerView: 3,
				spaceBetween: 2,
			},
			992: {
				slidesPerView: 6,
				slidesPerGroup: 6,
				spaceBetween: 2,
			},
		},
	});
}

function tabSlider() {
	new Swiper(".tabSlider", {
		slidesPerView: 3,
		slidesPerGroup: 3,
		spaceBetween: 2,
		centeredSlides: true,
		centeredSlidesBounds: true,
		breakpoints: {
			320: {
				slidesPerView: 3,
			},
			992: {
				slidesPerView: 6,
			},
		},
	});
}

function recomProductSwiper(menu) {
	new Swiper(".recomSlide", {
		spaceBetween: 16,
		slidesPerView: 1,
		slidesPerGroup: 1,
		centeredSlides: true,
		pagination: {
			el: ".swiper-pagination-recomSlide",
			clickable: true,
		},
		autoplay: true,
		navigation: {
			// nextEl: ".swiper-button-next",
			// prevEl: ".swiper-button-prev",
			nextEl: ".swiper-button-next-review",
			prevEl: ".swiper-button-prev-review",
		},
	});
}

function noticeSwiper() {
	var noticeswipers = new Swiper(".noticeSlide", {
		direction: "vertical",
		loop: true,
		autoplay: {
			delay: 2500,
			disableOnInteraction: false,
		},
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	});
}

function mainSnbSwiper() {
	var swiper = new Swiper(".snbSwiper", {
		slidesPerView: "auto",
		preventClicks: true,
		preventClicksPropagation: false,
		observer: true,
		observeParents: true,
	});

}

// END SLIDERS

function allMenuOpen() {
	$(".allMenu").toggle();
	$(this).toggleClass("open");
}

function mobileMenuListener() {
	// 모바일 사이즈
	if ($(window).width() < 768) {
		$(".depth1 svg").on("click", function (event) {
			event.stopPropagation();
			var $parent = $(this).closest(".depth1");
			var $depth2 = $parent.next(".depth2");

			if ($depth2.hasClass("open")) {
				$depth2.removeClass("open");
				$depth2.slideUp(300);
			} else {
				$depth2.addClass("open");
				$depth2.slideDown(300);
			}
		});
		// PC 사이즈
	} else {
		$(".depth2").show();
		$(".depth1").off("click");
		$(".screen-darken").hide();
	}

	$(window)
		.resize(function () {
			var width = window.innerWidth;
			if (width < 767) {
				$(".depth1 svg").on("click", function (event) {
					event.stopPropagation();
					var $parent = $(this).closest(".depth1");
					var $depth2 = $parent.next(".depth2");

					if ($depth2.hasClass("open")) {
						$depth2.removeClass("open");
						//$(this).next(".depth2").slideUp(300);
					} else {
						$depth2.addClass("open");
						//$(this).next(".depth2").slideDown(300);
					}
				});
			} else {
				$(".depth2").show();
				$(".depth1").off("click");
				$(".screen-darken").hide();
			}
		})
		.resize();

	document
		.querySelectorAll("[data-trigger]")
		.forEach(function (everyelement) {
			let offcanvas_id = everyelement.getAttribute("data-trigger");

			everyelement.addEventListener("click", function (e) {
				e.preventDefault();
				show_offcanvas(offcanvas_id);
			});
		});

	document.querySelectorAll(".btn-close").forEach(function (everybutton) {
		everybutton.addEventListener("click", function (e) {
			e.preventDefault();
			close_offcanvas();
		});
	});

	document
		.querySelector(".tgTop")
		.addEventListener("click", function (event) {
			close_offcanvas();
		});

	document.querySelectorAll(".depth2 a").forEach(function (everybutton) {
		everybutton.addEventListener("click", function (e) {
			e.preventDefault();
			close_offcanvas();
		});
	});
}

function darken_screen(yesno) {
	if (yesno == true) {
		document.querySelector(".screen-darken").classList.add("active");
	} else if (yesno == false) {
		document.querySelector(".screen-darken").classList.remove("active");
	}
}

function close_offcanvas() {
	darken_screen(false);
	document.querySelector(".mobile-offcanvas").classList.remove("show");
	document.body.classList.remove("offcanvas-active");
}

function show_offcanvas(offcanvas_id) {
	darken_screen(true);
	document.getElementById(offcanvas_id).classList.add("show");
	document.body.classList.add("offcanvas-active");
}

function productDetailSwiper() {
	//product detail image slide
	var detailswiper = new Swiper(".prodThumb", {
		spaceBetween: 20,
		slidesPerView: 6,
		freeMode: true,
		watchSlidesProgress: true,
	});
	var detailswiper2 = new Swiper(".prodSlide", {
		spaceBetween: 0,
		thumbs: {
			swiper: detailswiper,
		},
	});
}

function productDetailScroll() {
	//product detail scroll fix
	var width = window.innerWidth;
	if (width > 767) {
		$(window).scroll(function () {
			var scroll = $(window).scrollTop();
			if (scroll >= 1200) {
				$(".prodMenu").addClass("fix");
				$(".prodSide").addClass("fix");
			} else {
				$(".prodMenu").removeClass("fix");
				$(".prodSide").removeClass("fix");
			}

			var scrollBottom = $(document).height() - $(window).scrollTop();
			// if (scrollBottom < 1300) {
			// 	$(".prodSide").addClass("fixBtm");
			// } else {
			// 	$(".prodSide").removeClass("fixBtm");
			// }
			// console.log(scroll);
		});
	} else {
		$(window).scroll(function () {
			var scroll = $(window).scrollTop();
			if (scroll >= 1200) {
				$(".prodMenu").addClass("fix");
				$(".prodSide").addClass("fix");
			} else {
				$(".prodMenu").removeClass("fix");
				$(".prodSide").removeClass("fix");
			}

			var scrollBottom = $(document).height() - $(window).scrollTop();
			// if (scrollBottom < 1300) {
			// 	$(".prodSide").addClass("fixBtm");
			// } else {
			// 	$(".prodSide").removeClass("fixBtm");
			// }
			// console.log(scroll);
		});
	}

	$(window)
		.resize(function () {
			var width = window.innerWidth;
			if (width > 767) {
				$(window).scroll(function () {
					var scroll = $(window).scrollTop();
					if (scroll >= 1200) {
						$(".prodMenu").addClass("fix");
						$(".prodSide").addClass("fix");
					} else {
						$(".prodMenu").removeClass("fix");
						$(".prodSide").removeClass("fix");
					}

					var scrollBottom =
						$(document).height() - $(window).scrollTop();
					// if (scrollBottom < 1300) {
					// 	$(".prodSide").addClass("fixBtm");
					// } else {
					// 	$(".prodSide").removeClass("fixBtm");
					// }
					// console.log(scrollBottom);
				});
			} else {
			}
		})
		.resize();

	//more product detail image
	$(".imgDetail").on("click", function () {
		$(".prodDeImg .imgWrap").addClass("on");
		$(this).hide();
		$(".imgDetailOff").show();
	});

	$(".imgDetailOff").on("click", function () {
		$(".prodDeImg .imgWrap").removeClass("on");
		$(this).hide();
		$(".imgDetail").show();
	});

	$(window).scroll(function () {
		var scrollTop = $(window).scrollTop();
		var prodDeCont01, prodDeCont02, prodDeCont03;

		var prod1 = $(".prodDescription");
		if (prod1.length) {
			prodDeCont01 = prod1.offset().top - 50;
		}

		var prod2 = $(".prodDeImg");
		if (prod2.length) {
			prodDeCont02 = prod2.offset().top - 50;
		}

		var prod3 = $(".prodReview");
		if (prod3.length) {
			prodDeCont03 = prod3.offset().top - 50;
		}

		if (scrollTop > prodDeCont01) {
			$(".prodMenu a:first-child").addClass("on");
		} else {
			$(".prodMenu a:first-child").removeClass("on");
		}
		if (scrollTop > prodDeCont02) {
			$(".prodMenu a").removeClass("on");
			$(".prodMenu a:nth-child(2)").addClass("on");
		} else {
			$(".prodMenu a:nth-child(2)").removeClass("on");
		}
		if (scrollTop > prodDeCont03) {
			$(".prodMenu a").removeClass("on");
			$(".prodMenu a:nth-child(3)").addClass("on");
		} else {
			$(".prodMenu a:nth-child(3)").removeClass("on");
		}
	});
}

function checkoutPaymentScroll() {
	//checkout scroll fix
	var width = window.innerWidth;
	if (width > 767) {
		$(window).scroll(function () {
			var scroll = $(window).scrollTop();
			if (scroll >= 300) {
				$(".amtRight").addClass("fix");
			} else {
				$(".amtRight").removeClass("fix");
			}
		});
	}
}

function cartScroll() {
	//cart scroll fix
	var width = window.innerWidth;
	if (width > 768) {
		$(window).scroll(function () {
			var scroll = $(window).scrollTop();
			if (scroll >= 300) {
				$(".cartTotal").addClass("fix");
			} else {
				$(".cartTotal").removeClass("fix");
			}
		});
	}
}

function totalInfoScroll() {
	//totalInfo scroll fix

	var body = document.body,
		html = document.documentElement;

	var height = Math.max(
		body.scrollHeight,
		body.offsetHeight,
		html.clientHeight,
		html.scrollHeight,
		html.offsetHeight
	);

	if (height > 2950) {
		$(window).scroll(function () {
			var scroll = $(window).scrollTop();
			if (scroll > 2950) {
				$(".amtRight-joint").addClass("fix");
			} else {
				$(".amtRight-joint").removeClass("fix");
			}
		});
	}
}

function shareOnListenner() {
	//product detail url share
	$(".shareCont").toggle();

	// $(".shareOn").click(function () {
	//     $(".shareCont").toggle();
	// });
}

function termBtnListener() {
	//amt terms
	$(".termBtn").click(function () {
		$(".terms").toggleClass("on");
	});
}

function sendVaccount() {
	var HForm = document.payment;
	HForm.target = "payment";
	HForm.action = "https://pay.billgate.net/vaccount/certify.jsp";

	var option =
		"width=500,height=500,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,left=150,top=100";
	window.open("", "payment", option);

	HForm.submit();
}

function mobiCartTgListener() {
	//cart product info
	$(".mobiCartTg").click(function () {
		$(".cartTotal, .amtRight").toggleClass("on");
		$(".mobiWrap").slideToggle(200);
		$(".totalInfo").slideToggle(200);
	});
	$(window)
		.resize(function () {
			var width = window.innerWidth;
			if (width > 1000) {
				$(".mobiWrap").show();
				$(".totalInfo").show();
			} else {
				$(".mobiWrap").hide();
				$(".totalInfo").hide();
			}
		})
		.resize();
}

function catePdtSwiper() {
	var cateswipers = new Swiper(".iconCateSlide", {
		direction: "vertical",
		slidesPerView: 1,
		spaceBetween: 0,
		autoplay: true,
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
	});
}

function processSwiper(menu) {
	new Swiper(".mainRecomm", {
		spaceBetween: 16,
		slidesPerView: 1,
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
			renderBullet: function (index, className) {
				return (
					'<span class=" nav-item ' +
					className +
					'">' +
					menu[index] +
					"</span>"
				);
			},
		},
		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
		},
	});
}

function addZeroInPrice(value) {
	return parseFloat(value, 10)
		.toFixed(2)
		.replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
}


function hideMode(el){
	$(el).modal('hide');
	// Sử dụng Vanilla JavaScript
	var myModal = document.getElementById(el);
	var modal = new bootstrap.Modal(myModal);
	modal.hide();
}

function initialSwiper(
	selector,
	config,
	thumb,
	type,
	configThumb,
	classActive
) {
	if (type === "THUMBNAIL") {
		let thumbSwiper = new Swiper("." + thumb, configThumb);
		let newConfig = { ...config, thumbs: { swiper: thumbSwiper } };
		let slider = new Swiper("." + selector, newConfig);

		setActiveThumb(thumb, classActive);
		slider.on("slideChange", function (e) {
			setActiveThumb(thumb, classActive);
		});
	} else {
		new Swiper("." + selector, config);
	}
}

function setActiveThumb(selector, classList) {
	let slide = document.querySelectorAll("." + selector + " .swiper-slide");
	let slideToActive = document.querySelectorAll(
		"." + selector + " .swiper-slide.swiper-slide-thumb-active"
	);
	let classListReplace = classList.split(" ");
	slide.forEach((s) => {
		s.classList.remove(...classListReplace);
	});

	slideToActive.forEach((sa) => {
		sa.classList.add(...classListReplace);
	});
}

// noite for contact page

function addEventListenerShowForContactPage() {

	$(".btn-open-sub-0, .btn-open-sub-1, .btn-open-sub-2").on("click", function() {
    // Lấy phần tử .sub-noite liền kề sau phần tử cha của nút được nhấp vào
    var subNoiteElement = $(this).closest('.row').next('.sub-noite');
    // Đảo ngược lớp "opened" của phần tử .sub-noite
    subNoiteElement.toggleClass("opened");
    // Thực hiện hiệu ứng slideToggle cho phần tử .sub-noite
    subNoiteElement.slideToggle(700);
});




}



