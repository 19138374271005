function addEventListenerForPaymentMethod() {
  setTimeout(() => $("#payment-method").show(), 100);

  $(".field-payment-method input[type=radio]").unbind();
  $(".field-payment-method input[type=radio]").bind("click", function () {
    
    const method = $(this).attr("data-method");
    $(".payment-form").slideUp();
    $(".payment-form[data-method=" + method + "]").slideDown(500);
  });

  const _inputs = $(".field-payment-method input[type=radio]");
  if (_inputs && _inputs.length > 0) {
    $(_inputs[0]).click();
  }
}
